.footer-container {
  display: flex;
  gap: 1rem;
  padding: 1rem;
  padding-bottom: 2rem;
  height: fit-content;
  align-items: center;
  flex-wrap: wrap;
}

.logo-image {
  height: 2rem;
}
