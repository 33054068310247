.experience-section .experience-type-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.experience-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  overflow-y: hidden;
  background-image: url("../../images/experience/background.jpg");
  background-size: cover;
  background-position: center;
  padding: 2rem 0;
  gap: 4rem 0rem;
}

.top-wrapper {
  grid-column: 1 / 3;
}

@media only screen and (max-width: 1200px) {
  .top-wrapper {
    grid-column: 1;
  }
  .experience-grid {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
}

.experience-container {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  width: 100%;
  overflow-y: hidden;
}

.experience-container > *:nth-child(n) {
  z-index: calc(1);
  /* Forcing all the children to have a fixed z-order fixes shennanigans with shifting z-order */
}

@media only screen and (max-width: 1000px) {
  .skills-container {
    overflow-y: hidden;
  }
}
