.bg-image {
  filter: brightness(70%);
  position: relative;
}

.flex-coloumn-container {
  /* background: url("../../../images/hanonSystems/automotive.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  backdrop-filter: blur(10px); */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
}

.duties-list {
  margin: auto 3rem;
  min-width: 17rem;
  width: 40vw;
  max-width: 50rem;
}

.auto-image {
  width: 20rem;
  max-width: 85vw;
  border-radius: 10%;
}

.hanon-image {
  width: 30rem;
  max-width: 85vw;
}

.title-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem 4rem;
}

.job-description-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 1rem;
  overflow: hidden;
}

.split-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  gap: 2rem 3rem;
  flex-wrap: wrap;
  background-color: #ebfcfa;
  padding: 3rem 0;
  width: 100%;
}

.charger-image-container {
  position: relative;
}
.charger-image {
  width: 100vw;
  height: 10rem;
  object-fit: cover;
  filter: brightness(70%);
}

.contributions {
  margin-top: 0;
  padding: 0;
  overflow: hidden;
  background-color: #212222;
}

.contributions-list {
  margin: auto 3rem;
  margin-left: 0rem;
  margin-bottom: 2rem;
  width: 40vw;
  min-width: 17rem;
  max-width: 40rem;
  list-style: none;
}

.contributions-list *:not(:last-child) {
  margin-bottom: 0.7rem;
}

.collaboration {
  width: 100%;
  display: grid;
  grid-template-columns: 600px 1fr;
  margin-top: 0;
  padding: 0;
  background-color: rgb(136, 200, 214);
}

@media screen and (max-width: 1100px) {
  .collaboration {
    grid-template-columns: 1fr;
  }
}

.collaboration-image-container {
  position: relative;
  width: 100%;
  max-width: 35rem;
  height: 20rem;
  object-position: center;
  overflow: hidden;
}
@media screen and (max-width: 1100px) {
  .collaboration-image-container {
    max-width: none;
    height: 13rem;
  }
}

.collaboration-image {
  width: 100%;
  object-fit: contain;
  filter: brightness(60%);
}

.collaboration-list {
  width: 85%;
}

.spacer {
  background-color: rgb(17, 49, 49);
  height: 1rem;
  width: 100%;
}

.applied-skills-container {
  box-sizing: border-box;
  width: 100%;
  background-position: center;
  background-image: url("../../../images/hanonSystems/skills.jpg");
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  gap: 1rem;
  padding: 2rem 1rem;
}

.chip-container-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.chip-skills-container {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  padding: 1rem;
  justify-content: center;
  max-width: 40rem;
}

.reference-prompt-container {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;
}
