.work-card {
  height: 20rem;
  width: 90%;
  max-width: 64rem;
  display: grid;
  grid-template-columns: 2fr 3fr;
  border-radius: 0 0 2rem 0;
  overflow: hidden;
  border: 2px solid rgb(255, 255, 255);
  transition: all 0.2s;
}

@media only screen and (max-width: 1200px) {
  .work-card {
    max-width: 40rem;
  }
}

.work-image-container {
  background-size: cover;
  background-position: center;
  display: inline-flex;
  align-items: center;
  flex-direction: column-reverse;
  padding: 0.7rem 0;
  max-width: 100%;
  overflow: hidden;
}

.work-title-container {
  display: inline-block;
  padding: 0.5rem;
  overflow: hidden;
  max-width: 85%;
  width: 85%;
}

.title-text {
  padding: 0;
  margin: 0;
  font-family: Catamaran;
  font-weight: 500;
  text-align: center;
}

.work-description-container {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  padding: 0.7rem;
  padding-top: 1.5rem;
  overflow: hidden;
}

.work-card:hover:not(:last-child) {
  transform: translateX(1rem);
}

.work-card:hover ~ .work-card {
  transform: translateY(-13rem);
}

.work-card:not(:first-child) {
  margin-bottom: -14rem;
  box-shadow: 0.25rem 0.75rem 0.75rem rgba(0, 0, 0, 0.15);
}

.link-hover:hover {
  filter: brightness(0.92);
  cursor: pointer;
}

.description-text {
  padding-top: 0.5rem;
  font-weight: 300;
  max-height: 13rem;
  flex-grow: 1;
  vertical-align: top;
  flex-grow: 1;
}
