.contact-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0.5rem;
  gap: 2rem;
}

.contact-methods-grid {
  display: grid;
  width: 100%;
  gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
}
