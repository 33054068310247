:root {
  --dark-colour: #74cee9;
  --light-colour: #65d4a6;
}

.zSymbol {
  fill: var(--dark-colour);
  transform-origin: 50% 50%;
  transition: all 500ms;
  opacity: 50%;
}

.mSymbol {
  fill: var(--light-colour);
  transform-origin: 50% 50%;
  transition: all 300ms ease-in-out;
  opacity: 50%;
}

.zActive {
  transform: translateX(-17%) translateY(2%) rotate(-90deg);
  opacity: 100%;
  transition: all 1.5s ease-in-out;
}

.mSymbol.mActive {
  transform: translateX(17%) rotate(90deg);
  opacity: 100%;
  transition: all 1.5s ease-in-out;
}

#logo-frame:hover .zSymbol {
  transform: translateX(-17%) translateY(2%) rotate(-90deg);
  opacity: 100%;
}
#logo-frame:hover .mSymbol {
  transform: translateX(17%) rotate(90deg);
  opacity: 100%;
}
