.drawn-profile-pic {
  height: 20rem;
  border-radius: 50%;
}

.home-page-container {
  display: flex;
  justify-content: center;
  background-image: url("../../images/homePageHero.jpg");
  background-position: center top;
  background-size: cover;
}

.section-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.quick-options {
  background-color: white; /*linear-gradient(white, white 80%, rgba(0, 0, 0, 0));*/
  padding: 3rem 0 6rem 0;
  width: 100%;
}

.emphasis {
  font-style: italic;
  font-weight: 400;
}

/* .drawn-profile-pic.drawn-profile-pic-active {
} */
