.project-card {
  width: 100%;
  /* overflow: hidden; */
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
}

.project-image-container {
  min-height: 30rem;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-image: url("../../images/projects/mech323.jpg");

  padding: 1rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}

.project-click-wrapper {
  width: 100%;
  text-decoration: none;
  color: black;
}

.project-title-container {
  padding: 1rem;
  width: fit-content;
  height: fit-content;

  text-align: center;
}

.acoord {
  width: 100%;
}

.used-chip-container {
  margin-top: 2rem;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
}

.button-container {
  margin-top: 2rem;
  display: flex;
  width: 100%;
  justify-content: center;
}
