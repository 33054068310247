.projects-container {
  margin: 2rem 0rem;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}

@media only screen and (max-width: 800px) {
  .projects-container {
    grid-template-columns: 1fr;
  }
}
