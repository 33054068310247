.contact-method-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
  border: #98b7f5 1rem solid;
  border-radius: 1rem;
  padding: 1rem;
  min-height: 8rem;
}

.contact-method-title-container {
  display: flex;
  gap: 1rem;
  width: 100%;
  justify-content: space-between;
}

.contact-email-image {
  width: 100%;
  height: 3rem;
  object-fit: cover;
  object-position: center;
}
