.skill-card {
  height: 15rem;
  width: 90%;
  max-width: 50rem;
  display: grid;
  grid-template-columns: 2fr 3fr;
  border-radius: 0 0 2rem 0;
  overflow: hidden;
  border: 2px solid rgb(255, 255, 255);
  transition: all 0.2s;
}

@media only screen and (max-width: 1200px) {
  .skill-card {
    max-width: 40rem;
  }
}

.skill-image-container {
  background-size: cover;
  background-position: center;
  display: inline-flex;
  align-items: center;
  flex-direction: column-reverse;
  padding: 0.7rem 0;
  max-width: 100%;
  overflow: hidden;
}

.skill-title-container {
  display: inline-block;
  padding: 0.5rem;
  overflow: hidden;
  max-width: 85%;
  width: 85%;
}

.title-text {
  padding: 0;
  margin: 0;
  font-family: Catamaran;
  font-weight: 500;
  text-align: center;
}

.skill-description-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 1rem;
  padding-top: 1.3rem;
}

.skill-card:hover:not(:last-child) {
  transform: translateX(1rem);
}

.skill-card:hover ~ .skill-card {
  transform: translateY(-7rem);
}

.skill-card:not(:first-child) {
  margin-bottom: -8rem;
  box-shadow: 0.25rem 0.75rem 0.75rem rgba(0, 0, 0, 0.15);
}
.description-text {
  padding: 0;
  margin: 0;
  font-weight: 300;
  width: 100%;
  height: 100%;
  white-space: pre-line;
  max-height: 15rem;
}
