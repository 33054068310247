canvas {
  border: blueviolet solid 3px;
  cursor: pointer;
}

.solver-app {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.material-property-box {
  min-width: 15rem;
  width: 45%;
  border: #79a1f2 solid 2px;
  border-radius: 1rem;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.node-card-grid {
  display: grid;
  gap: 1rem 2rem;
  width: 100%;
  max-height: 430px;
  overflow-y: auto;
  grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
}

.save-options-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: baseline;
}

.user-save-cards-grid {
  display: grid;
  gap: 1rem 2rem;
  width: 100%;
  max-height: 430px;
  overflow-y: auto;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
}
